import { template as template_cafbd647555d45cda9cdd90ed606ef9b } from "@ember/template-compiler";
const FKLabel = template_cafbd647555d45cda9cdd90ed606ef9b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
