import { template as template_e44538d44a824adb917d51255c3aff98 } from "@ember/template-compiler";
const WelcomeHeader = template_e44538d44a824adb917d51255c3aff98(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
